import React from "react";

export default function IconFile(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.213 0C17.6747 0 20 2.16273 20 5.38122V14.6188C20 17.8654 17.7462 19.9769 14.2582 19.997L5.78805 20C2.32645 20 0 17.8373 0 14.6188V5.38122C0 2.1336 2.25379 0.023104 5.74181 0.00401808L14.2119 0H14.213ZM14.213 1.50678L5.74732 1.5108C3.18415 1.52486 1.65153 2.97137 1.65153 5.38122V14.6188C1.65153 17.0447 3.19846 18.4932 5.78695 18.4932L14.2527 18.4902C16.8159 18.4761 18.3485 17.0276 18.3485 14.6188V5.38122C18.3485 2.9553 16.8026 1.50678 14.213 1.50678ZM14.0007 13.5346C14.4565 13.5346 14.8264 13.8721 14.8264 14.288C14.8264 14.7039 14.4565 15.0414 14.0007 15.0414H6.05131C5.59549 15.0414 5.22554 14.7039 5.22554 14.288C5.22554 13.8721 5.59549 13.5346 6.05131 13.5346H14.0007ZM14.0007 9.32918C14.4565 9.32918 14.8264 9.6667 14.8264 10.0826C14.8264 10.4984 14.4565 10.836 14.0007 10.836H6.05131C5.59549 10.836 5.22554 10.4984 5.22554 10.0826C5.22554 9.6667 5.59549 9.32918 6.05131 9.32918H14.0007ZM9.08428 5.1335C9.54011 5.1335 9.91005 5.47102 9.91005 5.88689C9.91005 6.30276 9.54011 6.64028 9.08428 6.64028H6.05098C5.59516 6.64028 5.22521 6.30276 5.22521 5.88689C5.22521 5.47102 5.59516 5.1335 6.05098 5.1335H9.08428Z"
        fill="currentColor"
      />
    </svg>
  );
}
