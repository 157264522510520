// icon:close-o | CSS Icons https://css.gg/ | Astrit
import * as React from "react";

function IconClose(props) {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.25333 17L11.3333 12.92L15.4133 17L17 15.4133L12.92 11.3333L17 7.25333L15.4133 5.66667L11.3333 9.74667L7.25333 5.66667L5.66667 7.25333L9.74667 11.3333L5.66667 15.4133L7.25333 17ZM11.3333 22.6667C9.76556 22.6667 8.29222 22.369 6.91333 21.7736C5.53444 21.1782 4.335 20.3709 3.315 19.3517C2.295 18.3317 1.48769 17.1322 0.893067 15.7533C0.298445 14.3744 0.000755556 12.9011 0 11.3333C0 9.76556 0.297689 8.29222 0.893067 6.91333C1.48844 5.53444 2.29576 4.335 3.315 3.315C4.335 2.295 5.53444 1.48769 6.91333 0.893067C8.29222 0.298445 9.76556 0.000755556 11.3333 0C12.9011 0 14.3744 0.297689 15.7533 0.893067C17.1322 1.48844 18.3317 2.29576 19.3517 3.315C20.3717 4.335 21.1794 5.53444 21.7747 6.91333C22.3701 8.29222 22.6674 9.76556 22.6667 11.3333C22.6667 12.9011 22.369 14.3744 21.7736 15.7533C21.1782 17.1322 20.3709 18.3317 19.3517 19.3517C18.3317 20.3717 17.1322 21.1794 15.7533 21.7747C14.3744 22.3701 12.9011 22.6674 11.3333 22.6667ZM11.3333 20.4C13.8644 20.4 16.0083 19.5217 17.765 17.765C19.5217 16.0083 20.4 13.8644 20.4 11.3333C20.4 8.80222 19.5217 6.65833 17.765 4.90167C16.0083 3.145 13.8644 2.26667 11.3333 2.26667C8.80222 2.26667 6.65833 3.145 4.90167 4.90167C3.145 6.65833 2.26667 8.80222 2.26667 11.3333C2.26667 13.8644 3.145 16.0083 4.90167 17.765C6.65833 19.5217 8.80222 20.4 11.3333 20.4Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default IconClose;
