import React from "react";

export default function IconSend(props) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.27418 21.1807L3.06926 34.7512C2.92845 35.3455 3.28579 35.6948 3.4482 35.8178C3.61061 35.9408 4.04129 36.1967 4.58177 35.9035L32.8304 20.5048C33.3286 20.2315 33.3833 19.7603 33.3832 19.5735C33.3859 19.3896 33.3264 18.9312 32.8463 18.6627L4.63047 3.21409C4.0953 2.92151 3.65785 3.17927 3.49559 3.30246C3.33051 3.42848 2.96511 3.78382 3.10097 4.38082L6.28231 18.1797L21.33 18.0919C22.1558 18.0872 22.831 18.7547 22.8371 19.5838C22.8432 20.4129 22.1745 21.0896 21.3487 21.0943L6.27418 21.1807ZM1.21339 37.8395C0.23033 36.8553 -0.171385 35.452 0.155297 34.0596L3.55454 19.6712L0.184974 5.05596C-0.177317 3.47999 0.394872 1.89217 1.68451 0.912343C2.97274 -0.0688994 4.65107 -0.192948 6.06455 0.580187L34.2804 16.0288C35.578 16.7397 36.3764 18.0961 36.3801 19.5761C36.3767 21.0631 35.5658 22.4289 34.2591 23.14L6.01186 38.5372C4.59223 39.3133 2.91375 39.1855 1.63143 38.2043C1.48173 38.0884 1.34333 37.9696 1.21339 37.8395Z"
        fill="currentColor"
      />
    </svg>
  );
}
