export const data = [
  "Sector Agnostic",
  "B2B",
  "B2C",
  "AI/ML",
  "API",
  "AR/VR",
  "Analytics",
  "Automation",
  "BioTech",
  "Cloud",
  "Consumer Tech",
  "Creator Economy",
  "Crypto/Blockchain",
  "D2C",
  "DeepTech",
  "Developer Tools",
  "E-Commerce",
  "Education",
  "Climate Tech",
  "Fintech",
  "Gaming",
  "Healthtech",
  "IoT (Internet of Things)",
  "Legaltech",
  "Logistics and Supply Chain",
  "Manufacturing",
  "Media and Entertainment",
  "Mobility and Transportation",
  "PropTech (Property Technology)",
  "Robotics",
  "Saas (Software as a Service)",
  "SpaceTech",
  "SportsTech",
  "Telecommunications",
  "Travel and Tourism",
  "Wearables",
  "Insurtech",
  "Agtech (Agriculture Technology)",
  "Clean Energy / Renewable Energy",
  "Foodtech",
  "HRtech (Human Resources Technology)",
  "B2B Marketplace",
  "Cybersecurity",
  "E-sports",
  "MarTech (Marketing Technology)",
  "MedTech (Medical Technology)",
  "Retail Tech",
  "others",
];
